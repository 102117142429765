import { debug } from './index';
let insideJsLibUrl;
const otherLibsUrls = [];
const head = document.getElementsByTagName('head')[0];
export function addLib(filename, url) {
    if (debug) {
        console.log('Lib found : ' + url);
    }
    if (filename.indexOf('inside') >= 0) {
        insideJsLibUrl = url;
    }
    else {
        otherLibsUrls.push(url);
    }
}
export function loadLibs() {
    if (!insideJsLibUrl) {
        console.error(`InsideJs lib was not found in archive !`);
        return;
    }
    const promises = otherLibsUrls.map((url) => loadJsLib(url));
    return Promise.all(promises).then(() => loadJsLib(insideJsLibUrl));
}
export function cleanLibs() {
    URL.revokeObjectURL(insideJsLibUrl);
    insideJsLibUrl = undefined;
    while (otherLibsUrls.length > 0) {
        URL.revokeObjectURL(otherLibsUrls.pop());
    }
    while (scripts.length > 0) {
        const script = scripts.pop();
        head.removeChild(script);
    }
}
const scripts = [];
function loadJsLib(url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = function () {
            scripts.push(script);
            resolve();
        };
        script.onerror = function () {
            head.removeChild(script);
            reject(`Error loading lig ${url}`);
        };
        script.src = url;
        head.appendChild(script);
    });
}
