import * as JSZip from 'jszip';
import { addLib } from './LibsManagement';
import { addUrlToCache } from './UrlsCache';
import { debug } from './index';
let json;
export function processZipFile(file) {
    return loadFileToArrayBuffer(file).then((content) => {
        const zip = new JSZip();
        return zip
            .loadAsync(content)
            .then((zip) => {
            if (debug) {
                console.log('ZIP loaded with files : ', zip.files);
            }
            const promises = Object.values(zip.files).map((zipEntry) => {
                return loadZipEntry(zipEntry);
            });
            return Promise.all(promises).then();
        })
            .then(() => json);
    });
}
function loadFileToArrayBuffer(file) {
    return new Promise((resolve, reject) => {
        try {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (e) => {
                reject('Error reading file : ' + e);
            };
            fileReader.readAsArrayBuffer(file);
        }
        catch (e) {
            reject('Error reading file : ' + e);
        }
    });
}
function loadZipEntry(zipEntry) {
    if (zipEntry.dir) {
        return Promise.resolve();
    }
    const filename = zipEntry.name;
    if (filename.indexOf('.DS_Store') >= 0 || filename.indexOf('__MACOSX') >= 0) {
        return Promise.resolve();
    }
    if (debug) {
        console.log('Load ZIP entry : ' + filename);
    }
    if (filename == 'tour.json') {
        if (debug) {
            console.log('JSON found !');
        }
        return zipEntry.async('text').then((content) => {
            json = JSON.parse(content);
        });
    }
    if (filename.startsWith('libs')) {
        if (debug) {
            console.log('Lib found : ' + filename);
        }
        return zipEntry.async('arraybuffer').then((content) => {
            const filename = zipEntry.name;
            const fileType = getTypeFromFileName(filename);
            const blob = new Blob([content], { type: fileType });
            const url = URL.createObjectURL(blob);
            addLib(filename, url);
        });
    }
    return zipEntry.async('arraybuffer').then((content) => {
        const filename = zipEntry.name;
        const fileType = getTypeFromFileName(filename);
        const blob = new Blob([content], { type: fileType });
        const url = URL.createObjectURL(blob);
        addUrlToCache(filename, url);
    });
}
const FILES_TYPES = {
    svg: 'image/svg+xml',
    png: 'image/png',
    html: 'text/html',
    htm: 'text/html',
    ico: 'image/x-icon',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    json: 'application/json',
    js: 'application/javascript',
    pdf: 'application/pdf',
    css: 'text/css',
};
function getTypeFromFileName(filename) {
    const pointPosition = filename.lastIndexOf('.');
    const type = filename.slice(pointPosition + 1);
    return FILES_TYPES[type];
}
