const urls = new Map();
function cleanQueryParams(url) {
    return url.replace(/\?.*$/, '');
}
export function addUrlToCache(filename, url) {
    urls.set(filename, url);
}
export function getCachedUrlOrSame(url) {
    const cleanedUrl = cleanQueryParams(url);
    return urls.get(cleanedUrl) || url;
}
export function cleanCache() {
    for (const key in urls.keys) {
        URL.revokeObjectURL(urls.get(key));
        urls.delete(key);
    }
}
