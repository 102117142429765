// Surcharges des méthodes de InsideJs
import { getCachedUrlOrSame } from './UrlsCache';
export function overrideInsideJs() {
    const INSIDE = window.INSIDE;
    const oldLoaderImageFn = INSIDE.Loader.prototype.image;
    let oldLoaderImageFnBind;
    INSIDE.Loader.prototype.image = function (key, url, success, error, context) {
        if (!oldLoaderImageFnBind) {
            oldLoaderImageFnBind = oldLoaderImageFn.bind(this);
        }
        return oldLoaderImageFnBind(key, getCachedUrlOrSame(url), success, error, context);
    };
    const oldLoaderJsonFn = INSIDE.Loader.prototype.json;
    let oldLoaderJsonFnBind;
    INSIDE.Loader.prototype.json = function (key, url, onCompleteCallback, onCompleteContext, onErrorCallback, onErrorContext) {
        if (!oldLoaderJsonFnBind) {
            oldLoaderJsonFnBind = oldLoaderJsonFn.bind(this);
        }
        return oldLoaderJsonFnBind(key, getCachedUrlOrSame(url), onCompleteCallback, onCompleteContext, onErrorCallback, onErrorContext);
    };
    const oldLoadScriptFn = INSIDE.Loader.prototype.script;
    let oldLoadScriptFnBind;
    INSIDE.Loader.prototype.script = function (url, callback, context) {
        if (!oldLoadScriptFnBind) {
            oldLoadScriptFnBind = oldLoadScriptFn.bind(this);
        }
        return oldLoadScriptFnBind(getCachedUrlOrSame(url), callback, context);
    };
    const oldLoadCssFn = INSIDE.Loader.prototype.css;
    let oldLoadCssFnBind;
    INSIDE.Loader.prototype.css = function (url, callback, context) {
        if (!oldLoadCssFnBind) {
            oldLoadCssFnBind = oldLoadCssFn.bind(this);
        }
        return oldLoadCssFnBind(getCachedUrlOrSame(url), callback, context);
    };
}
// Hack pour les problèmes de CSS avec DomHotspot
export function hackDomHostspotCss(viewer) {
    const parentNode = viewer.container.dom.parentNode;
    const config = { childList: true };
    const callback = function (mutationsList, observer) {
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                for (const addedNode of mutation.addedNodes) {
                    if (addedNode.tagName === 'STYLE') {
                        const node = addedNode;
                        if (node.innerHTML.indexOf('.DomHotspot') > 0) {
                            node.innerHTML = hackDomHostspotUrls(node.innerHTML);
                        }
                    }
                }
            }
        }
    };
    const observer = new MutationObserver(callback);
    observer.observe(parentNode, config);
}
function hackDomHostspotUrls(innerHTML) {
    const regexp = /"plugins\/domhotspot\/css\/img\/(.*)"/;
    while (innerHTML.match(regexp)) {
        const [, filename] = innerHTML.match(regexp);
        const url = `plugins/domhotspot/css/img/${filename}`;
        innerHTML = innerHTML.replace(`"${url}"`, `"${getCachedUrlOrSame(url)}"`);
    }
    return innerHTML;
}
