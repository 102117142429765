import { getCachedUrlOrSame } from './UrlsCache';
import { debug } from './index';
let viewer;
let divForViewer;
export function createViewer(div, json) {
    if (debug) {
        console.log('Create viewer...');
        console.log('JSON=' + JSON.stringify(json));
    }
    return new Promise((resolve, reject) => {
        const INSIDE = window.INSIDE;
        viewer = new INSIDE.Viewer(div, json);
        if (INSIDE.VERSION.startsWith('2')) {
            divForViewer = div;
            resolve(viewer);
        }
        else {
            viewer.onMainConfigLoadComplete.add(() => {
                divForViewer = div;
                resolve(viewer);
            });
        }
        const oldFn_buildPrefixedUrl = viewer.buildPrefixedUrl;
        let oldFn_buildPrefixedUrl_binded;
        viewer.buildPrefixedUrl = (url) => {
            if (!oldFn_buildPrefixedUrl_binded) {
                oldFn_buildPrefixedUrl_binded = oldFn_buildPrefixedUrl.bind(viewer);
            }
            return oldFn_buildPrefixedUrl_binded(getCachedUrlOrSame(url));
        };
    });
}
export function destroyViewer() {
    if (viewer) {
        try {
            viewer.destroy();
        }
        catch (e) {
            console.error('Error destroying viewer : ', e);
        }
        viewer = null;
    }
    if (divForViewer) {
        while (divForViewer.firstChild) {
            divForViewer.removeChild(divForViewer.firstChild);
        }
        divForViewer = null;
    }
}
